<template>
  <div>
    <div style="background-color: #0c0c0c;">
      <div class="top-box">
        <div class="footer-title">{{$t('footer.package_list')}}</div>
        <img class="footer-banner" src="https://www.achencybe.com/img/pc/m3/1-7.png">
        <div class="footer-list">
          <div v-for="item in footerList" :key="item">
            <div class="circle"></div>
            <div>{{ $t(item) }}</div>
          </div>
        </div>
      </div>

      <div style="margin-top:114px;font-size:18px;line-height: 32px;">
        {{ slogan ? $t(slogan): $t('car_list.style') }}<br />
        For {{ title }}<br />
        <button
          style="width:156px;height:50px;font-size: 18px;background-color:#3d79fd;color:#fff;margin-top:34px;cursor: pointer;"
          @click="$emit('buy')">
          {{ $t('car_list.buy_now') }}
        </button>
      </div>
      <!--      <img style="width:100%;max-width:632px;margin:60px 0;" :src="https://www.achencybe.com/img/pc/order\challeimgnger\2-0-1.png">-->
      <img style="width:100%;max-width:800px;margin:60px 0;" :src="img">
    </div>

    <div class="footer-nav">
      <!--      <img src="../../assets/images/other/bottom_package_m3_2.png" alt="">-->
      <div class="nav-item" v-for="(item,idx) in navList" :key="item.text">
        <img :src="item.img" alt="">
        <a v-if="idx === 0" style="cursor: pointer;"  @click="$router.push({name:'FooterDetail',query:{type:'AC'}})">{{ $t(item.text) }}</a>
        <div v-if="idx !== 0">{{ $t(item.text) }}</div>
      </div>
    </div>
    <div style="padding:59px 0;font-size:12px;color:#8b8b8b;text-align: left;width:800px;margin:0 auto;">
      {{ $t('footer.remark_title') }}
      <br />

      <br />
      {{ $t('footer.remark_1') }}
      <br />
      {{ $t('footer.remark_2') }}
      <br />
      {{ $t('footer.remark_3') }}
      <br />
      {{ $t('footer.remark_4') }}
      <br />
      {{ $t('footer.remark_5') }}
      <br />
      {{ $t('footer.remark_6') }}
      <br />
      {{ $t('footer.remark_7') }}

    </div>
  </div>
</template>

<script>
export default {
  props: {
    slogan: String,
    title: String,
    img: String
  },
  data() {
    return {
      footerList: ["footer.product", "footer.gift", "footer.serial_number", "footer.instructions"],
      navList: [
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_1.png",
          text: "footer.support"
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_2.png",
          text: "footer.free_shipping"
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_3.png",
          text: "footer.free_gift"
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_4.png",
          text: "footer.installation"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.top-box {
  margin-top: 100px;
  .footer-title {
    margin: 0 auto;
    width: 100%;
    max-width: 1626px;
    font-size: 24px;
    padding: 60px 0 30px;
    border-bottom: 2px solid #201f1f;
  }

  .footer-banner {
    width: 20%;
    margin: 60px auto;
  }

  .footer-list {
    width: 32%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #727272;
      margin-bottom: 10px;
    }
  }
}

.footer-nav {
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  padding: 40px 0;

  .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #64beff;

    img {
      width: 105px;
    }

    a {

      color: #64beff;
      text-decoration: underline;
    }
  }

  .nav-item + .nav-item {
    margin-left: 100px;
  }
}
</style>