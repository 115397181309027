var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticStyle: { "background-color": "#0c0c0c" } }, [
      _c("div", { staticClass: "top-box" }, [
        _c("div", { staticClass: "footer-title" }, [
          _vm._v(_vm._s(_vm.$t("footer.package_list"))),
        ]),
        _c("img", {
          staticClass: "footer-banner",
          attrs: { src: "https://www.achencybe.com/img/pc/m3/1-7.png" },
        }),
        _c(
          "div",
          { staticClass: "footer-list" },
          _vm._l(_vm.footerList, function (item) {
            return _c("div", { key: item }, [
              _c("div", { staticClass: "circle" }),
              _c("div", [_vm._v(_vm._s(_vm.$t(item)))]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "114px",
            "font-size": "18px",
            "line-height": "32px",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.slogan ? _vm.$t(_vm.slogan) : _vm.$t("car_list.style"))
          ),
          _c("br"),
          _vm._v(" For " + _vm._s(_vm.title)),
          _c("br"),
          _c(
            "button",
            {
              staticStyle: {
                width: "156px",
                height: "50px",
                "font-size": "18px",
                "background-color": "#3d79fd",
                color: "#fff",
                "margin-top": "34px",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("buy")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("car_list.buy_now")) + " ")]
          ),
        ]
      ),
      _c("img", {
        staticStyle: { width: "100%", "max-width": "800px", margin: "60px 0" },
        attrs: { src: _vm.img },
      }),
    ]),
    _c(
      "div",
      { staticClass: "footer-nav" },
      _vm._l(_vm.navList, function (item, idx) {
        return _c("div", { key: item.text, staticClass: "nav-item" }, [
          _c("img", { attrs: { src: item.img, alt: "" } }),
          idx === 0
            ? _c(
                "a",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "FooterDetail",
                        query: { type: "AC" },
                      })
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(item.text)))]
              )
            : _vm._e(),
          idx !== 0 ? _c("div", [_vm._v(_vm._s(_vm.$t(item.text)))]) : _vm._e(),
        ])
      }),
      0
    ),
    _c(
      "div",
      {
        staticStyle: {
          padding: "59px 0",
          "font-size": "12px",
          color: "#8b8b8b",
          "text-align": "left",
          width: "800px",
          margin: "0 auto",
        },
      },
      [
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_title")) + " "),
        _c("br"),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_1")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_2")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_3")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_4")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_5")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_6")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("footer.remark_7")) + " "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }